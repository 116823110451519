// i18next-extract-mark-ns-start payment-gateway-spain

import {ButtonLink} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {PaymentMethods} from 'components/PaymentMethods';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {Card, CardImage} from 'components/Card';
import sell from 'images/sell.svg';
import modular from 'images/modular.svg';
import {SupportLink} from 'components/links/Support';
import clock from 'images/clock.svg';
import mobile_payment from 'images/mobile_payment.svg';
import CardsContainer from 'components/CardsContainer';
import payment_gateway from 'images/payment_gateway.svg';
import {GradientBox} from 'components/landings/GradientBox';
import {ScreenSizes} from '../../types/responsive';
import baloon from 'images/baloon.svg';
import integration from 'images/integration.svg';
import {Clients, defaultClientList} from 'components/Clients';
import {AnchorLink} from 'components/AnchorLink';
import coinstack from 'images/coinstack.svg';
import {Place} from 'components/GoogleReviews';
import {StyledDiv} from 'components/StyledSx';
import {LearnMoreLinks} from 'components/LearnMoreLinks';

const IndexBackground = styled(StyledDiv)`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;
const IndexContent = styled.div`
  padding-bottom: 0;
  max-width: 620px;
  flex: 3;
  @media (max-width: ${ScreenSizes.md}) {
    padding-top: 0;
    max-width: 100%;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    min-height: 410px;
  }
`;

const IndexSection = styled(Section)`
  padding-bottom: 0;
  padding-top: 50px;
  align-items: center;
  @media (max-width: 1440px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .index-image {
    margin-right: -50px;
    width: 600px;
    flex: 2;
    @media (max-width: ${ScreenSizes.lg}) {
      width: 500px;
    }
    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

export const PaymentCard = styled.div`
  flex-basis: 100%;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 32px;
  }
`;

export const CardWrapper = styled.div`
  max-width: 280px;

  h4 {
    max-width: 100%;
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;

  p {
    font-size: 1.25rem;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;
const clientsList = defaultClientList.slice(0, 5).map((client) => {
  return {
    ...client,
    width: 180 * client.scale
  };
});
const PaymentGatewaySpain: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const title = t('Payment Gateway Spain');
  const description = t(
    'Looking for a secure, affordable, and easy to integrate payment gateway in Spain? Use MONEI to accept more payment methods and increase sales. Start now ››'
  );
  return (
    <>
      <IndexBackground>
        <Content>
          <SEO title={title} description={description} />
          <IndexSection>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Increase conversions with the best payment gateway in Spain</Trans>
              </SectionHeader>
              <Trans parent="p">
                If you have an e-commerce business in Spain, you already know you need a payment
                gateway to configure online payments. Choose the best payment gateway in Spain to
                accept major and local payment methods, stay PSD2 compliant, and sell cross-border.
                With one integration, get built-in virtual POS services, a customized payment page,
                endless paymet methods, and more.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <img
              className="index-image"
              src={payment_gateway}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </IndexSection>
        </Content>
      </IndexBackground>
      <Content>
        <Clients clientList={clientsList} />
      </Content>
      <Content sx={{paddingBottom: '24px'}}>
        <Section sx={{maxWidth: 800, textAlign: 'center'}} column>
          <SectionHeader underline sx={{marginTop: {sm: '36px'}}}>
            <Trans>Why choose MONEI as your Spain payment gateway?</Trans>
          </SectionHeader>
          <Trans parent="p">
            Meet all your{' '}
            <InternalPageLink slug="online-payments">online payments</InternalPageLink> needs with
            one partner.{' '}
            <BlogLink slug="accepting-credit-card-payments-without-merchant-account">
              Merchant acquiring
            </BlogLink>{' '}
            services (virtual POS),{' '}
            <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>,{' '}
            <SupportLink slug="/articles/360017953818">custom domain</SupportLink> setup, and more —
            get it all from a single integration with the{' '}
            <InternalPageLink slug="payment-gateway">best payment gateway</InternalPageLink> in
            Spain. Create your MONEI account in under 10 minutes to enjoy these features and more.
          </Trans>
        </Section>
        <CardsContainer>
          <Card>
            <CardImage src={integration} alt="" style={{top: -50, width: 100}} />
            <CardWrapper>
              <Trans>Manage your entire payment stack and reporting from a single platform</Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={sell} alt="" style={{top: -48, width: 100}} />
            <CardWrapper>
              <Trans>
                Increase brand awareness and trust with a{' '}
                <SupportLink slug="/categories/360003383258">customized</SupportLink> checkout page
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={modular} alt="" style={{top: -55, width: 100}} />
            <CardWrapper>
              <Trans>
                Improve transaction acceptance rates with{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={coinstack} alt="" style={{top: -50, width: 100}} />
            <CardWrapper>
              <Trans>
                Save money on transaction fees with{' '}
                <InternalPageLink slug="pricing">dynamic pricing</InternalPageLink> and enjoy fast
                settlements
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={clock} alt="" style={{top: -65, width: 90}} />
            <CardWrapper>
              <Trans>
                Earn recurring revenue with flexible{' '}
                <InternalPageLink slug="features/recurring-payments">
                  subscription plans
                </InternalPageLink>
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={mobile_payment} alt="" style={{top: -50, width: 110}} />
            <CardWrapper>
              <Trans>
                Charge customers with or without a website using{' '}
                <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>
              </Trans>
            </CardWrapper>
          </Card>
        </CardsContainer>
        <SectionActions style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
          <SignUpButton variant="light" style={{marginRight: 15}}>
            <Trans>Open an Account</Trans>
          </SignUpButton>
          <ContactSalesButton />
        </SectionActions>
      </Content>
      <Content fullWidth>
        <Section
          column
          sx={{maxWidth: '960px', textAlign: 'center', margin: 'auto', paddingBottom: '0'}}>
          <SectionHeader underline tagName="h3">
            <Trans>
              Accept the most popular payment methods in Spain (and beyond) from one platform
            </Trans>
          </SectionHeader>
          <Trans parent="p">
            Increase conversions, reach more{' '}
            <BlogLink slug="cross-border-ecommerce">cross-border e-commerce</BlogLink> customers,
            and improve the checkout experience by accepting popular{' '}
            <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
            <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>, credit and
            debit <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
            <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>, and
            other <BlogLink slug="local-payment-methods">local payment methods</BlogLink> including{' '}
            <InternalPageLink slug="payment-methods/sepa-direct-debit">
              SEPA Direct Debit
            </InternalPageLink>
            , <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>, and
            more.
          </Trans>
        </Section>
        <PaymentCard>
          <PaymentMethods exclude={[]} />
          <ButtonLink href="/payment-methods" variant="light">
            <Trans>Vew all payment methods</Trans>
          </ButtonLink>
        </PaymentCard>
      </Content>
      <Content>
        <Section sx={{textAlign: 'center'}} column>
          <SectionHeader underline>
            <Trans>Seamless integration with the best payment gateway in Spain</Trans>
          </SectionHeader>
          <Trans parent="p" style={{maxWidth: '960px', margin: 'auto'}}>
            Use plugins for{' '}
            <InternalPageLink slug="shopify-payment-gateway">Shopify</InternalPageLink>,{' '}
            <InternalPageLink slug="prestashop-payment-gateway">PrestaShop</InternalPageLink>,{' '}
            <InternalPageLink slug="woocommerce-payment-gateway">WooCommerce</InternalPageLink>,{' '}
            <InternalPageLink slug="magento-payment-gateway">Magento</InternalPageLink> (Adobe
            Commerce), and Wix to quickly and easily connect your e-commerce website with MONEI’s
            payment gateway. Have a custom-built website? Use our{' '}
            <AnchorLink href="https://docs.monei.com/api/">payments API</AnchorLink>.
          </Trans>
          <Partners />
        </Section>
      </Content>

      {/*      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>*/}
      <Content sx={{marginTop: '100px'}}>
        <Section column centered sx={{textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Learn more about MONEI’s payment gateway</Trans>
          </SectionHeader>
          <LearnMoreLinks />
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible'}} sx={{paddingTop: 0}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Power your cross-border payments with MONEI</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Get started today (without obligation) to test integrations and payment methods.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <CtaImage src={baloon} />
        </GradientBox>
      </Content>
    </>
  );
};

export default PaymentGatewaySpain;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-gateway-spain"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
